<template>
  <div>
    <Pane />

    <a-card class="container" :loading="loading">
      <div class="header">
        <div class="name">{{detail.name}}{{chapter.name ? '：' + chapter.name : ''}}</div>

        <div class="extra">
          <span class="label">本章节进度：</span>
          <a-progress :percent="progress" />
        </div>
      </div>

      <div v-if="fileType === 'mp4' || fileType === 'mov'">
        <video
          v-if="chapter.attachment"
          :src="chapter.attachment.completePath"
          controls
          autoplay
          style="width: 100%"
        ></video>
      </div>

      <div
        class="wrapper"
        v-else-if="fileType === 'pptx' || fileType === 'ppt' || fileType === 'pdf'"
      >
        <img class="image" :src="activeImage" alt />
        <div class="menu">
          <div class="title">本章内容</div>
          <div class="item" v-for="(item, index) in subfiles" :key="item" @click="toggle(index)">
            <a-tooltip placement="left">
              <template slot="title">
                <span>点击切换</span>
              </template>
              <img :src="item" />
            </a-tooltip>
          </div>
        </div>
      </div>
    </a-card>
  </div>
</template>

<script>
import request from "@/api/request";

function fetchDetail(data) {
  return request({
    url: "/office-service/study/user/course/" + data.courseId,
    method: "post"
  });
}

function firstStudy(data) {
  return request({
    url: "/office-service/study/user/play/request",
    method: "post",
    data
  });
}

function startStudy(data) {
  return request({
    url: "/office-service/study/user/play/start",
    method: "post",
    data
  });
}

function continueStudy(data) {
  return request({
    url: "/office-service/study/user/play/loose",
    method: "post",
    data
  });
}

// function pauseStuty(data) {
//   return request({
//     url: "/office-service/study/user/play/pause",
//     method: "post",
//     data
//   });
// }

export default {
  data() {
    return {
      courseId: "",
      chapterId: "",

      loading: false,
      detail: {},

      progress: 0, // 这个章节的学习进度

      watchSession: "", // 下一次请求的序列号
      reqt: "",

      activeImageIndex: 0, // 正在看的图片索引

      isPause: false
    };
  },

  computed: {
    chapter() {
      if (Array.isArray(this.detail.chapters)) {
        const item = this.detail.chapters.find(
          item => item.id === this.chapterId
        );
        return item ? item : {};
      } else {
        return {};
      }
    },
    fileType() {
      return this.chapter.attachment ? this.chapter.attachment.ext : "";
    },
    subfiles() {
      return this.chapter.attachment &&
        typeof this.chapter.attachment.subfiles === "string"
        ? this.chapter.attachment.subfiles.split(",")
        : [];
    },

    activeImage() {
      const image = this.subfiles[this.activeImageIndex];
      return image ? image : "";
    }
  },

  mounted() {
    // document.addEventListener("blur", this.onBlur);

    this.getDetail();
  },

  destroyed() {
    clearInterval(this.timer);
    // document.removeEventListener("blur", this.onBlur);
  },

  methods: {
    getDetail() {
      const { courseId, chapterId } = this.$route.query;
      this.courseId = courseId;
      this.chapterId = chapterId;
      if (!courseId || !chapterId) return;

      this.loading = true;

      fetchDetail({
        courseId: this.courseId
      })
        .then(res => {
          if (res) {
            this.detail = res;

            firstStudy({
              courseId: this.courseId,
              chapterId: this.chapterId
            }).then(res => {
              console.log("start study", res);
              if (res) {
                this.watchSession = res;
                this.onStartStudy();
              }
            });
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },

    onStartStudy() {
      startStudy({
        courseId: this.courseId,
        chapterId: this.chapterId,
        watchSession: this.watchSession
      }).then(res => {
        if (res) {
          this.progress = res.progress || 0;
          this.reqt = res.reqt;

          if (res.resMsg) {
            const that = this;
            this.$error({
              title: "错误",
              content: res.resMsg,
              onOk() {
                that.$close(that.$route.path);
              }
            });
          } else {
            this.setTimer();
          }
        }
      });
    },

    setTimer() {
      this.timer = setInterval(() => {
        continueStudy({
          courseId: this.courseId,
          chapterId: this.chapterId,
          watchSession: this.watchSession,
          reqdate: this.reqt
        })
          .then(res => {
            if (res) {
              this.progress = res.progress || 0;
              this.reqt = res.reqt;

              if (res.finished) {
                clearInterval(this.timer);
                const that = this;
                this.$success({
                  title: "提示",
                  content: "您已学习完毕",
                  onOk() {
                    that.$close(that.$route.path);
                  }
                });
              } else if (res.resMsg) {
                clearInterval(this.timer);
                const that = this;
                this.$error({
                  title: "错误",
                  content: res.resMsg,
                  onOk() {
                    that.$close(that.$route.path);
                  }
                });
              }
            }
          })
          .catch(err => {
            clearInterval(this.timer);
            const that = this;
            this.$error({
              title: "错误",
              content: err ? err.toString() : "异常错误",
              onOk() {
                that.$close(that.$route.path);
              }
            });
          });
      }, 10000);
    },

    toggle(index) {
      this.activeImageIndex = index;
    },

    onBlur() {
      if (!this.isPause) {
        this.isPause = true;
        clearInterval(this.timer);

        const that = this;
        this.$error({
          title: "错误",
          content: "学习已暂停，点击继续学习",
          onOk() {
            that.isPause = false;
            that.setTimer();
          }
        });
      }
    }
  }
};
</script>

<style lang="less" scoped>
.container {
  .header {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
    padding-bottom: 8px;
    border-bottom: 1px solid #f0f0f0;

    .name {
      font-weight: bold;
      font-size: 20px;
    }

    .extra {
      min-width: 320px;
      margin-left: auto;
      display: flex;
      align-items: center;
      justify-content: flex-end;

      .label {
        flex-shrink: 0;
        margin-right: 8px;
      }
    }
  }

  .wrapper {
    display: grid;
    grid-template-columns: 1fr 200px;
  }

  .image {
    display: block;
    width: 100%;
  }
  .menu {
    border-left: 1px solid #f0f0f0;
    padding: 8px;
    width: 200px;
    flex-shrink: 0;

    .title {
      font-size: 14px;
      font-weight: bold;
      margin-bottom: 8px;
    }

    .item {
      cursor: pointer;
      border: 1px solid #f0f0f0;
      margin-bottom: 8px;
      img {
        display: block;
        width: 100%;
      }
    }

    .active {
      border-color: #1890ff;
    }
  }
}
</style>